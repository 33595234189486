<template>
  <v-container class="app-page-with-filter">
    <template>
      <v-row class="px-4">
        <v-col>
          <v-text-field
            v-model="search"
            clearable
            flat
            dark
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Buscar"
            class="no-border-style"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex align-end justify-end">
          <v-btn dark icon @click="openFilter = !openFilter">
            <img class="ma-2" src="@/assets/header/filter.svg" />
          </v-btn>
        </v-col>
      </v-row>

      <v-banner v-model="openFilter" transition="slide-y-transition" class="filtro-banner">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col class="py-0">
                <h3 style="width:max-content" class="title">Filtros de búsqueda</h3>
              </v-col>

              <v-spacer></v-spacer>

              <v-col class="text-right py-0">
                <v-btn icon x-small color="secondary" @click="openFilter = !openFilter">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-0">
            <v-row>
              <v-col cols="12" xs="12" sm="4" md="3" class="py-0">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="filters.date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :clearable="true" v-model="computedDate" label="Mes" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker 
                    :min="minFilterDate" 
                    :max="maxFilterDate" 
                    v-model="filters.date" 
                    type="month" 
                    scrollable 
                    @input="modal = false; $refs.dialog.save(filters.date)"
                  ></v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" xs="12" sm="4" md="3" class="py-0">
                <v-select 
                  :items="filters.tipo"
                  item-text="value"
                  item-value="id"
                  :clearable="true"
                  v-model="filters.idTRep"
                  label="Tipo"></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="4" md="3" class="py-0">
                <v-select 
                  :items="filters.vista" 
                  item-text="value"
                  item-value="id"
                  :clearable="true"
                  v-model="filters.status" 
                  label="Visto"></v-select>
              </v-col>
            
              <v-spacer></v-spacer>

              <v-col cols="12" xs="12" sm="4" md="3" class="text-right">
                <v-btn color="primary" outlined @click="filtrarNotificaciones()">
                  Filtrar
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-banner>
    </template>

    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="table-recibos">
          <v-skeleton-loader
            v-if="pager.loading"
            ref="skeleton"
            :boilerplate="false"
            type="table"
            :tile="false"
            class="mx-auto"
          ></v-skeleton-loader>

          <v-data-table
            v-else
            :headers="computedHeaders"
            :items="filterRecords" 
            :search="search"
            @click:row="navigate"
            class="app-table"
            mobile-breakpoint="0"
          >
            <template v-slot:item.Ver="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="navigate(item)"
              >
                fas fa-search
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getNotificaciones, getNotificacionesSearch, updateNotificacion } from "@/api/core";

export default {
  name: "notificaciones",

  components: {},

  data() {
    return {
      loading: true,
      openFilter: false,
      pager: {
        records: [],
        loading: false,
      },
      search: "",
      headers: [
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "DescTipo",
        },
        { text: "Descripcion", value: "Detalle", sortable: false },
        { text: "Mes Ref.", value: "MesDeLiq", sortable: false },
        //{ text: "Ingresada", value: "Visto", sortable: false },
        { text: "Ver", value: "Ver", sortable: false },
        { text: "Visto", value: "Visto", sortable: false },
      ],
      menu: false,
      modal: false,
      filters: {
        tipo: [
          {
            id: 0,
            value: 'Generales'
          },
          {
            id: 1,
            value: 'Recibos'
          },
          {
            id: 2,
            value: 'Detalle de IRPF'
          },
                  {
            id: 3,
            value: 'Detalle de Licencia'
          },
                  {
            id: 4,
            value: 'Detalle de Aguinaldo'
          },
                  {
            id: 5,
            value: 'Reliquidaciones'
          },
          {
            id: 6,
            value: 'Resolución DGI'
          },
                  {
            id: 7,
            value: 'Avisos'
          },
          {
            id: 8,
            value: 'Adelantos'
          },
          {
            id: 9,
            value: 'Datos para Liquidar'
          },
          {
            id: 10,
            value: 'Planif. de licencias'
          }
        ],
        vista: [
          {
            id: 1,
            value: 'No Visto'
          },
          {
            id: 2,
            value: 'Visto'
          }
        ],
        idTRep: null,
        date: null,
        status: 1
      },
      maxFilterDate: null,
      minFilterDate: null
    };
  },

  created() {
    let date = new Date();
    let month = date.getMonth()
    let year = date.getFullYear()
    this.maxFilterDate = year + '-' + (month + 1)
    this.minFilterDate = (year - 1) + '-01'
  },

  watch: {},

  computed: {
    filterRecords: function() {
      return this.pager.records.filter(item => {
        if(!this.filters.status) return true
        if(this.filters.status == 1 && item.Visto == "") return true
        if(this.filters.status == 2 && item.Visto != "") return true
        return false
      })
    },

    computedHeaders () {
      return this.headers.filter( item => {
        if(window.innerWidth < 600)
          return item.value != 'Detalle'
        return true
      })
    },

    computedDate: {
      get: function() {
        if(this.filters.date) {
          let info = this.filters.date.split('-')
          return info[1] + '/' + info[0]
        }
        return null
      },
      set: function(newValue) {
        if(newValue == null) {
          this.filters.date = null
        }
      }
    }
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Notificaciones");
    this.$store.commit("stopProcessing");

    this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;
      this.pager.loading = true;

      const data = await getNotificaciones();

      if (data && data.length > 0) {
        this.pager.records = this.pager.records.concat(data);
      }

      this.loading = false;
      this.pager.loading = false;
    },

    async filtrarNotificaciones() {
      this.loading = true
      this.pager.loading = true
      let filters = {
        'month': this.filters.date ? this.filters.date + '-01' : null,
        'idTRep': this.filters.idTRep,
        'status': this.filters.status
      }
      const data = await getNotificacionesSearch(filters)
      this.pager.records = data

      this.loading = false
      this.pager.loading = false
    },

    navigate(row) {
      this.markVisto(row)
      if (row.idTRep != 0) {
        this.$router.push({
          name: "notificacion_pdf",
          params: { id: row.idDetaCalc },
        });
      }
    },

    markVisto(item) {
      if(item.Visto == "") {
        var params = {
          idDato: item.idDetaCalc,
          TipoDato: 2,
          Visto: 1
        }

        /*Para marcar como vista la liquidación:
        Tipo Dato=1
        idDato=Id_Encabezado

        Para marcar como visto el PDF
        Tipo Dato=2
        idDato=Id_DetaCalc*/

        updateNotificacion(params)
      }
    },

    exportData() {
      alert("Proximamente")
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scope>
.table-recibos {
  box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5) !important;
  border-radius: 20px 20px 0px 20px !important;
}
</style>
